import './MenuItem.css';
import {forwardRef} from 'react';
import {classNames} from '../../utilities/classNames';
import {A} from '../A';

export const MenuItem = forwardRef<HTMLLIElement, UI.MenuItem.Props>(function MenuItem(
  {children, className, href, ...attributes},
  ref,
) {
  const classes = classNames('MenuItem truncate', className);

  return (
    <li {...attributes} className={classes} ref={ref}>
      <A href={href}>{children}</A>
    </li>
  );
});
