import type {PropsWithChildren} from 'react';
import {MenuGroup} from '@cindedi/ui/components/MenuGroup';
import {Card} from '@cindedi/ui/components/Card';
import {MenuItem} from '@cindedi/ui/components/MenuItem';
import {PublicMobileMenuTrigger} from '../PublicMobileMenuTrigger';

export interface PublicMenuProps extends PropsWithChildren {
  //
}

export function PublicMenu() {
  return (
    <Card className="flex flex-col gap-4 p-4 relative" shadow md:shadow={false}>
      <div className="lg:hidden absolute right-0 top-0 m-2">
        <PublicMobileMenuTrigger />
      </div>
      <MenuGroup title="Site" aria-label="Menu principal do site">
        <MenuItem href="/">Principal</MenuItem>
        <MenuItem href="/noticias">Notícias</MenuItem>
        <MenuItem href="/sobre-o-cindedi">Sobre o CINDEDI</MenuItem>
        <MenuItem href="/contato">Contato</MenuItem>
        <MenuItem href="/links">Links</MenuItem>
        <MenuItem href="/videos">Vídeos</MenuItem>
      </MenuGroup>
      <MenuGroup
        title="Pesquisa"
        aria-label="Links relacionados às atividades de pesquisa do CINDEDI"
      >
        <MenuItem href="/membros">Membros</MenuItem>
        <MenuItem href="/linhas-de-pesquisa">Linhas de Pesquisa</MenuItem>
        <MenuItem href="/intercambio">Intercâmbio</MenuItem>
        <MenuItem href="/publicacoes">Publicações</MenuItem>
        <MenuItem href="/trabalhos">Trabalhos</MenuItem>
      </MenuGroup>
      <MenuGroup title="Eventos" aria-label="Links relacionados à eventos do CINDEDI">
        <MenuItem href="/encontros">Encontros do CINDEDI</MenuItem>
      </MenuGroup>
      <MenuGroup
        title="Atividades"
        aria-label="Links relacionados à atividades academicas do CINDEDI"
      >
        <MenuItem href="/disciplinas">Disciplinas</MenuItem>
      </MenuGroup>
    </Card>
  );
}
