import type {PropsWithChildren} from 'react';
import {Span} from '@cindedi/ui/components/Span';

export interface PublicHeaderProps extends PropsWithChildren {
  coordinator: string;
}

export function PublicHeader({coordinator}: PublicHeaderProps) {
  return (
    <header>
      <div className="h-32 w-full border-b-primary-5 border-b-[2rem] bg-primary-8 flex justify-around items-center">
        <img
          alt="Universidade de São Paulo"
          src="/images/usp.svg"
          className="h-10 hidden md:inline"
        />
        <div className="h-full w-full hidden xs:inline min-w-96 max-w-screen-xs mt-9 bg-no-repeat bg-contain bg-center">
          <picture>
            <source srcSet="/images/logo.webp" type="image/webp" />
            <img
              alt="Centro de Investigação Sobre Desenvolvimento Humano e Educação Infantil"
              src="/images/logo.png"
            />
          </picture>
        </div>
        <div className="h-full w-64 inline xs:hidden mt-9 bg-no-repeat bg-contain bg-center">
          <picture>
            <source srcSet="/images/reduced-logo.webp" type="image/webp" />
            <img
              alt="Centro de Investigação Sobre Desenvolvimento Humano e Educação Infantil"
              src="/images/reduced-logo.png"
            />
          </picture>
        </div>
        <div className="h-full hidden md:inline">
          <picture>
            <source srcSet="/images/ffclrp.webp" type="image/webp" />
            <img
              alt="Faculdade de Filosofia, Ciência e Letras de Ribeirão Preto"
              src="/images/ffclrp.png"
            />
          </picture>
        </div>
      </div>
      <div className="bg-primary-3 px-2 py-1 hidden xs:block">
        <Span fontWeight="bold">Coordenador(a):</Span> <Span>{coordinator}</Span>
      </div>
    </header>
  );
}
