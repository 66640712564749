import {useService} from '@cindedi/react-application';
import {useComputed} from '@cindedi/signals/hooks/useComputed';
import {IconButton} from '@cindedi/ui/components/IconButton';
import {type PropsWithChildren} from 'react';

export interface PublicMobileMenuTriggerProps extends PropsWithChildren {
  //
}

export function PublicMobileMenuTrigger() {
  const menu = useService('organization/menu');
  const isVisible = useComputed(() => menu.isVisible);

  return (
    <IconButton
      size="lg"
      variant="tertiary"
      icon={isVisible.value ? 'close' : 'mobile-menu'}
      onClick={menu.toggle}
    />
  );
}
