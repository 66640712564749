import {useService} from '@cindedi/react-application';
import {useComputed} from '@cindedi/signals/hooks/useComputed';
import {H1} from '@cindedi/ui/components/Heading/H1';
import {type PropsWithChildren} from 'react';
import {PublicMobileMenuTrigger} from '../PublicMobileMenuTrigger';

export interface PublicTitleBarProps extends PropsWithChildren {
  //
}

export function PublicTitleBar() {
  const titlebar = useService('organization/titlebar');
  const title = useComputed(() => titlebar.title);

  return (
    <div className="flex items-start gap-2 whitespace-normal">
      <div className="lg:hidden">
        <PublicMobileMenuTrigger />
      </div>
      {title.value && (
        <H1 size="md" className="flex whitespace-normal break-before-auto">
          {title.value}
        </H1>
      )}
    </div>
  );
}
