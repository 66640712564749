import {useContext} from 'react';
import {ApplicationContext} from '../../context/ApplicationContext';
import {CindediError} from '@cindedi/error';
import type {Cindedi} from '@cindedi/spec/application';

export function useApp(): Cindedi.Application {
  const app = useContext(ApplicationContext);

  if (!app) {
    throw new CindediError({
      status: 500,
      title: 'Missing Application Context',
      detail: 'useApp hook must be used within an ApplicationProvider.',
    });
  }

  return app;
}
