import {useReducer} from 'react';
import {useIsomorphicLayoutEffect} from '../useIsomorphicLayoutEffect';
import {useApp} from '../useApp';

export function useService<K extends keyof Application.Services, T extends Application.Services[K]>(
  name: K,
): T {
  const app = useApp();
  const [, forceUpdate] = useReducer((c) => c + 1, 0);

  useIsomorphicLayoutEffect(
    () =>
      app.container.addEventListener('service-container:set', (event) => {
        if (name in event.data) forceUpdate();
      }),
    [forceUpdate, name],
  );

  return app.container.use(name) as T;
}
