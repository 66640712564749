import type {PropsWithChildren} from 'react';
import {Copyright} from '../Copyright';
import {A} from '@cindedi/ui/components/A';

export interface PublicFooterProps extends PropsWithChildren {
  telephone: string;
  address: string;
}

export function removeNonNumericCharFromString(value: string): string {
  return value.replace(/\D/g, '');
}

export function PublicFooter({address, telephone}: PublicFooterProps) {
  return (
    <footer className="flex flex-col w-full">
      <div className="w-full bg-primary-2 text-sm flex flex-col p-2">
        <address className="w-full flex not-italic">
          <div className="flex flex-col w-full" dangerouslySetInnerHTML={{__html: address}} />
          <div className="flex flex-col w-full text-right">
            <span>Telefone de contato</span>
            <A href={`tel:+55${removeNonNumericCharFromString(telephone)}`}>{telephone}</A>
          </div>
        </address>
        <p className="w-full text-right flex flex-row gap-1 items-center justify-end">
          <span>Site desenvolvido por Olavo Amorim Santos</span>
          <span>|</span>
          <A href="/acesso/login">Acesso</A>
        </p>
      </div>
      <div className="bg-primary-9 w-full text-center border-t-4 border-primary-4">
        <Copyright className="text-shade-1" size="sm" />
      </div>
    </footer>
  );
}
