import {Container} from '@cindedi/ui/components/Container';
import type {PropsWithChildren} from 'react';
import {PublicHeader} from '../PublicHeader';
import {PublicFooter} from '../PublicFooter';
import {PublicMenu} from '../PublicMenu';
import {PublicTitleBar} from '../PublicTitleBar';
import {useService} from '@cindedi/react-application';
import {useComputed} from '@cindedi/signals/hooks/useComputed';
import {useRouteLoaderData} from '@remix-run/react';

export interface LayoutCoverProps extends PropsWithChildren {
  src?: string;
}

export function LayoutCover({src}: LayoutCoverProps) {
  if (src) {
    return (
      <div
        className="absolute lg:-m-4 w-full h-32 bg-no-repeat bg-cover bg-center"
        style={{backgroundImage: `url(${src})`}}
      />
    );
  }

  return null;
}

export interface PublicLayoutProps extends PropsWithChildren {
  cover?: string;
}

export function PublicLayout({children, cover}: PublicLayoutProps) {
  const menu = useService('organization/menu');
  const isVisible = useComputed(() => menu.isVisible);
  const metadata = useRouteLoaderData('routes/_public+/_layout') as Record<string, string>;

  return (
    <Container className="min-h-screen flex flex-col">
      <PublicHeader coordinator={metadata.coordinator} />
      <div className="bg-shade-1 flex-1 flex h-full relative p-0 lg:p-4 gap-4">
        <LayoutCover src={cover} />
        <aside className="sticky top-4 z-10">
          <div className="hidden lg:block min-w-52">
            <PublicMenu />
          </div>
          <div
            className={`block transition-all lg:hidden absolute ${isVisible.value ? 'w-52' : 'w-0'}`}
          >
            <PublicMenu />
          </div>
        </aside>
        <main
          className={`w-full h-full -ml-4 lg:ml-0 sticky top-4 bg-shade-1 p-4 rounded ${cover ? 'mt-20' : ''}`}
        >
          <PublicTitleBar />
          {children}
        </main>
      </div>
      <PublicFooter address={metadata.address} telephone={metadata.telephone} />
    </Container>
  );
}
