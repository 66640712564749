import './MenuGroup.css';
import {forwardRef} from 'react';
import {classNames} from '../../utilities/classNames';
import {Span} from '../Span';

export const MenuGroup = forwardRef<HTMLMenuElement, UI.MenuGroup.Props>(function MenuGroup(
  {children, className, title, ...attributes},
  ref,
) {
  const classes = classNames('MenuGroup', className, {
    //
  });

  return (
    <nav {...attributes} className={classes} ref={ref}>
      <Span fontWeight="bold" className="text-primary-7 text-xs pl-1">
        {title}
      </Span>
      <ul>{children}</ul>
    </nav>
  );
});
