import {type ReadonlySignal, computed} from '@preact/signals-core';
import {useState, useSyncExternalStore} from 'react';

export function useComputed<T>(callback: () => T): ReadonlySignal<T> {
  const [signal] = useState(() => computed(callback));

  useSyncExternalStore(
    (listener) => signal.subscribe(listener),
    () => signal.peek(),
    () => signal.peek(),
  );

  return signal;
}
