import {forwardRef} from 'react';
import styles from './IconButton.module.css';
import {classNames} from '../../utilities/classNames';
import {Icon} from '../Icon';

export const IconButton = forwardRef<HTMLButtonElement, UI.IconButton.Props>(function Button(
  {className, icon, variant, tone, fullWidth, size, ...attributes},
  ref,
) {
  const classes = classNames('IconButton', styles.IconButton, className, {
    [styles['full-width']]: fullWidth === true,
    [styles[`size-${size}`]]: `size-${size}` in styles,
    [styles[`tone-${tone}`]]: `tone-${tone}` in styles,
    [styles[`variant-${variant}`]]: `variant-${variant}` in styles,
  });

  return (
    <button {...attributes} className={classes} ref={ref}>
      <Icon name={icon} />
    </button>
  );
});
